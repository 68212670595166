import { BaseElementColorsWidget } from '../../core/components/baseElementColorsWidget';

export class PackagingGraphicElementColorsWidget extends BaseElementColorsWidget {
  get name() {
    return 'packagingUvGraphicElementColorType';
  }

  get label() {
    return this.i18n.gettext('colore della grafica disponibili');
  }
}
