import { BaseElementSuggestedColorsWidget } from '../../core/components/baseElementSuggestedColorsWidget';

export class PackagingGraphicElementSuggestedColorsWidget extends BaseElementSuggestedColorsWidget {
  get name() {
    return 'packagingUvGraphicElementColorType';
  }

  get label() {
    return this.i18n.gettext('colore della grafica suggeriti');
  }
}
