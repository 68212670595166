import { BaseSelectionWidgetTwoColumns } from '../../core/components/baseSelectionWidgetTwoColumns';

export class PackagingUvGraphicWidget extends BaseSelectionWidgetTwoColumns {
  get name() {
    return 'packagingUvGraphicType';
  }

  get label() {
    return this.i18n.gettext('grafica sulla confezione');
  }
}
