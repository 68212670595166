import React from 'react';
import { BaseSelectionWidgetTwoColumns } from '../../core/components/baseSelectionWidgetTwoColumns';

export class PackagingUvPhotographerNameWidget extends BaseSelectionWidgetTwoColumns {
  get name() {
    return 'uvPhotographerName';
  }

  get label() {
    return this.i18n.gettext('nome fotografo');
  }

  get calculatePrice() {
    return false;
  }

  renderImage() {
    let image = null;
    if (this.props.items && this.props.items.map) {
      image = this.props.items.map((item) => {
        if (item.id === this.props.selected) {
          return <img key={item.id} style={{ width: '100%' }} src={item.fileUrl} alt={''} />;
        }
      });
    }
    return image;
  }

  renderContext() {
    return (
      <div className="product__formats" data-product-formats="">
        <div className="row">{this.renderItems()}</div>
        <div className="row">
          <div className="col-sm-12 padding-col-dx">{this.renderImage()}</div>
        </div>
      </div>
    );
  }
}
