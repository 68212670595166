import { LoadableCollection } from '../core/managers';
import { conf } from 'outlinejs';

import {
  UsbDrivePackagingColorMaterialType,
  UsbDrivePackagingUvGraphicType,
  UsbDrivePackagingUvGraphicThemeType,
  UsbDrivePackagingUvGraphicElementType,
  UsbDrivePackagingUvGraphicText,
  UsbDrivePackagingUvPhotographerNameType,
  UsbDrivePackagingUvPhotographerNameText,
  UsbDrivePackagingUvGraphicElementColorType,
  UsbDrivePackagingProduct,
  UsbDrivePackagingPrice
} from './models';

export class UsbDrivePackagingProductCollection extends LoadableCollection {
  get name() {
    return 'usbDrivePackagingProducts';
  }

  get url() {
    return conf.settings.USB_DRIVE_PACKAGING_PRODUCTS_URL;
  }

  get model() {
    return UsbDrivePackagingProduct;
  }
}

export class UsbDrivePackagingPriceCollection extends LoadableCollection {
  get name() {
    return 'usbDrivePackagingPrices';
  }

  get url() {
    return conf.settings.USB_DRIVE_PACKAGING_PRICES_URL;
  }

  get model() {
    return UsbDrivePackagingPrice;
  }
}

export class UsbDrivePackagingColorMaterialTypesCollection extends LoadableCollection {
  get name() {
    return 'colorMaterialTypes';
  }

  get url() {
    return conf.settings.USB_DRIVE_PACKAGING_COLOR_MATERIALS_URL;
  }

  get model() {
    return UsbDrivePackagingColorMaterialType;
  }

  get loadParams() {
    return ['formatType', 'packagingType'];
  }
}

export class UsbDrivePackagingUvGraphicsCollection extends LoadableCollection {
  get name() {
    return 'packagingUvGraphicTypes';
  }

  get url() {
    return conf.settings.USB_DRIVE_PACKAGING_UV_GRAPHIC_COLLECTIONS_URL;
  }

  get model() {
    return UsbDrivePackagingUvGraphicType;
  }

  get loadParams() {
    return ['formatType', 'packagingType', 'colorMaterialType'];
  }
}

export class UsbDrivePackagingUvGraphicThemeCollection extends LoadableCollection {
  get name() {
    return 'packagingUvGraphicThemeTypes';
  }

  get url() {
    return conf.settings.USB_DRIVE_PACKAGING_UV_GRAPHIC_THEMES_URL;
  }

  get model() {
    return UsbDrivePackagingUvGraphicThemeType;
  }

  get loadParams() {
    return ['formatType', 'packagingType', 'packagingUvGraphicType'];
  }
}

export class UsbDrivePackagingUvGraphicElementCollection extends LoadableCollection {
  get name() {
    return 'packagingUvGraphicElementTypes';
  }

  get url() {
    return conf.settings.USB_DRIVE_PACKAGING_UV_GRAPHIC_ELEMENTS_URL;
  }

  get model() {
    return UsbDrivePackagingUvGraphicElementType;
  }

  get loadParams() {
    return [
      'formatType',
      'packagingType',
      'packagingUvGraphicType',
      'packagingUvGraphicThemeType',
      'colorMaterialType'
    ];
  }
}

export class UsbDrivePackagingUvGraphicTextsCollection extends LoadableCollection {
  get bypassDefault() {
    return true;
  }

  get name() {
    return 'packagingUvGraphicTextsCollection';
  }

  get url() {
    return conf.settings.USB_DRIVE_PACKAGING_UV_GRAPHIC_TEXTS_URL;
  }

  get model() {
    return UsbDrivePackagingUvGraphicText;
  }

  get loadParams() {
    return [
      'formatType',
      'packagingType',
      'packagingUvGraphicType',
      'packagingUvGraphicElementType'
    ];
  }
}

export class UsbDrivePackagingUvPhotographerNameCollection extends LoadableCollection {
  get name() {
    return 'packagingUvPhotographerNameTypes';
  }

  get url() {
    return conf.settings.USB_DRIVE_PACKAGING_UV_PHOTOGRAPHER_NAMES_URL;
  }

  get model() {
    return UsbDrivePackagingUvPhotographerNameType;
  }

  get loadParams() {
    return ['formatType', 'packagingType'];
  }
}

export class UsbDrivePackagingUvPhotographerTextsCollection extends LoadableCollection {
  get bypassDefault() {
    return true;
  }

  get name() {
    return 'packagingUvPhotographerTextsCollection';
  }

  get url() {
    return conf.settings.USB_DRIVE_PACKAGING_UV_PHOTOGRAPHER_NAME_TEXTS_URL;
  }

  get model() {
    return UsbDrivePackagingUvPhotographerNameText;
  }

  get loadParams() {
    return ['formatType', 'packagingType', 'uvPhotographerName'];
  }
}

export class UsbDrivePackagingUvGraphicElementColorCollection extends LoadableCollection {
  get name() {
    return 'packagingUvGraphicElementColorTypes';
  }

  get url() {
    return conf.settings.USB_DRIVE_PACKAGING_UV_GRAPHIC_ELEMENT_COLOR_URL;
  }

  get model() {
    return UsbDrivePackagingUvGraphicElementColorType;
  }

  get loadParams() {
    return [
      'formatType',
      'packagingType',
      'colorMaterialType',
      'packagingUvGraphicType',
      'packagingUvGraphicElementType'
    ];
  }
}
