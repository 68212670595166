import { BaseModel } from '../core/models';
import { conf } from 'outlinejs';

export class UsbDrivePackagingConfiguration extends BaseModel {
  get urlRoot() {
    return conf.settings.USB_DRIVE_PACKAGING_CONFIGURATIONS_URL;
  }

  getAbsoluteUrl() {
    return this.urlRoot + this.id + '/';
  }

  get id() {
    return this.get('id');
  }

  get usbDriveConfigurationId() {
    return this.get('configurationId');
  }

  set usbDriveConfigurationId(value) {
    this.set('configurationId', value);
  }

  get productType() {
    return this.get('productType');
  }

  get productName() {
    return this.get('productName');
  }

  get formatType() {
    return this.get('formatType');
  }

  set formatType(value) {
    this.set('formatType', value);
  }

  get packagingType() {
    return this.get('packagingType');
  }

  set packagingType(value) {
    this.set('packagingType', value);
  }

  get colorMaterialType() {
    return this.get('colorMaterialType');
  }

  set colorMaterialType(value) {
    this.set('colorMaterialType', value);
  }

  get packagingUvGraphicElementType() {
    return this.get('packagingUvGraphicElementType');
  }

  set packagingUvGraphicElementType(value) {
    this.set('packagingUvGraphicElementType', value);
  }

  get packagingUvGraphicThemeType() {
    return this.get('packagingUvGraphicThemeType');
  }

  set packagingUvGraphicThemeType(value) {
    this.set('packagingUvGraphicThemeType', value);
  }

  get packagingUvGraphicType() {
    return this.get('packagingUvGraphicType');
  }

  set packagingUvGraphicType(value) {
    this.set('packagingUvGraphicType', value);
  }

  get packagingUvGraphicTexts() {
    return this.get('packagingUvGraphicTexts');
  }

  set packagingUvGraphicTexts(value) {
    this.set('packagingUvGraphicTexts', value);
  }

  get shopCode() {
    return this.get('shopCode');
  }

  set shopCode(value) {
    this.set('shopCode', value);
  }

  get previewImageUrl() {
    return this.get('previewImageUrl');
  }

  set previewImageUrl(value) {
    this.set('previewImageUrl', value);
  }

  get previewImageFileId() {
    return this.get('previewImageFileId');
  }

  set previewImageFileId(value) {
    this.set('previewImageFileId', value);
  }

  get uvPhotographerName() {
    return this.get('uvPhotographerName');
  }

  set uvPhotographerName(value) {
    this.set('uvPhotographerName', value);
  }

  get uvPhotographerNameTexts() {
    return this.get('uvPhotographerNameTexts');
  }

  set uvPhotographerNameTexts(value) {
    this.set('uvPhotographerNameTexts', value);
  }

  set packagingUvGraphicElementColorType(value) {
    this.set('packagingUvGraphicElementColorType', value);
  }

  get packagingUvGraphicElementColorType() {
    return this.get('packagingUvGraphicElementColorType');
  }

  get packagingUvGraphicElementSvgName() {
    return this.get('packagingUvGraphicElementSvgName');
  }

  set packagingUvGraphicElementSvgName(value) {
    this.set('packagingUvGraphicElementSvgName', value);
  }

  get previewStatus() {
    return this.get('previewStatus');
  }

  set previewStatus(value) {
    this.set('previewStatus', value);
  }

  get uvGraphicColorSelected() {
    return this.get('uvGraphicColorSelected');
  }

  set uvGraphicColorSelected(value) {
    this.set('uvGraphicColorSelected', value);
  }
}

export class UsbDrivePackagingProduct extends BaseModel {
  get id() {
    return this.get('code');
  }

  get frontAssetType() {
    return this.get('frontAssetType');
  }

  get frontAssetUrl() {
    return this.get('frontAssetUrl');
  }

  get uvGraphicColor() {
    return this.get('uvGraphicColor');
  }

  get svgAssetType() {
    return this.get('svgAssetType');
  }

  get svgAssetUrl() {
    return this.get('svgAssetUrl');
  }
}

export class UsbDrivePackagingPrice extends BaseModel {
  get price() {
    return this.get('price');
  }

  get formattedPrice() {
    return this.get('formattedPrice');
  }

  get currency() {
    return this.get('currency');
  }
}

/**------------------- lookups -------------------**/

export class UsbDrivePackagingColorMaterialType extends BaseModel {
  static get modelName() {
    return 'colorMaterialType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class UsbDrivePackagingUvGraphicType extends BaseModel {
  static get modelName() {
    return 'packagingUvGraphicType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class UsbDrivePackagingUvGraphicThemeType extends BaseModel {
  static get modelName() {
    return 'packagingUvGraphicThemeType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class UsbDrivePackagingUvGraphicElementType extends BaseModel {
  static get modelName() {
    return 'packagingUvGraphicElementType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get svgUrl() {
    return this.get('svgUrl');
  }

  get svgName() {
    return this.get('svgName');
  }
}

export class UsbDrivePackagingUvGraphicText extends BaseModel {
  static get modelName() {
    return 'packagingUvGraphicTexts';
  }

  get id() {
    return this.get('rowCode');
  }

  get rowCode() {
    return this.get('rowCode');
  }

  get rowNumber() {
    return this.get('rowNumber');
  }

  get name() {
    return this.get('name');
  }

  get minTextLength() {
    return this.get('minTextLength');
  }

  get maxTextLength() {
    return this.get('maxTextLength');
  }

  get fieldType() {
    return this.get('fieldType');
  }

  get contentType() {
    return this.get('contentType');
  }

  get isRequired() {
    return this.get('isRequired');
  }
}

export class UsbDrivePackagingUvPhotographerNameType extends BaseModel {
  static get modelName() {
    return 'uvPhotographerName';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }
}

export class UsbDrivePackagingUvPhotographerNameText extends BaseModel {
  static get modelName() {
    return 'uvPhotographerNameTexts';
  }

  get id() {
    return this.get('rowCode');
  }

  get rowCode() {
    return this.get('rowCode');
  }

  get name() {
    return this.get('name');
  }

  get isRequired() {
    return this.get('isRequired');
  }

  get minTextLength() {
    return this.get('minTextLength');
  }

  get maxTextLength() {
    return this.get('maxTextLength');
  }

  get rowNumber() {
    return this.get('rowNumber');
  }
}

export class UsbDrivePackagingUvGraphicElementColorType extends BaseModel {
  static get modelName() {
    return 'packagingUvGraphicElementColorType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get colorCode() {
    let color = this.get('colorCode');
    return `#${color}`;
  }

  get isSuggested() {
    return this.get('isSuggested');
  }
}
