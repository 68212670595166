import { GraphicCollapsableWidgetWithText } from '../../core/components/graphicCollapsableWidgetWithText';

export class PackagingColorMaterialTypeWidget extends GraphicCollapsableWidgetWithText {
  get name() {
    return 'colorMaterialType';
  }

  get label() {
    return this.i18n.gettext('Colore della confezione');
  }

  get calculatePrice() {
    return false;
  }

  //TODO: attivare quando ci sono le grafiche uv
  handleMouseOver(item) {
    this.props.delegate.setPreviewImage(item.bookImageUrl);
    this.props.delegate.setPreviewSvgColor(item.uvGraphicColor);
    this.props.delegate.setPreviewSvg();
  }

  handleMouseOut() {
    this.props.delegate.setPreviewSvgColor();
    this.props.delegate.setPreviewSvg();
    this.props.delegate.setPreviewImage();
  }

  handleClick(item) {
    super.handleClick(item);
    this.props.delegate.chosePreviewSvgColor(item.uvGraphicColor);
  }

  //Need if you shouldn't make the call to prices
  // propagateClick() {
  //   if (this.clickedItem) {
  //     this.setState({isChosen: true});
  //     this.props.delegate.setConfigurationItem(this.name, this.clickedItem.id, null, true);
  //   }
  // }
}
